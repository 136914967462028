export const getFirstDayOfTheMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const isSameMonth = (a, b) =>
    a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();

export const isSameDay = (a, b) =>
    a.getDate() === b.getDate() && isSameMonth(a, b);

export const getPreviousMonth = (date) => {
    const _date = new Date(date);

    _date.setMonth(_date.getMonth() - 1);

    return _date;
};

export const getNextMonth = (date) => {
    const _date = new Date(date);

    _date.setMonth(_date.getMonth() + 1);

    return _date;
};
