import React, { useEffect, useState } from "react";
import { createRef } from "react";
import Calendar from "react-calendar";
import { Col, Container, Row } from "reactstrap";
import { useBottomNav, useHeader } from "../../../hooks/redux";
import * as Strapi from "../../../hooks/strapi";
import Heading from "../../atoms/Heading/Heading";
import CalendarNavigation from "../../molecules/CalendarNavigation/CalendarNavigation";
import EventCard from "../../molecules/EventCard/EventCard";
import {
    getFirstDayOfTheMonth,
    isSameDay,
    isSameMonth,
} from "../../../utils/dates";

import "./events.scss";
import PlainText from "../../atoms/PlainText/PlainText";
import axios from "axios";

export const Events = () => {
    const today = new Date();
    const [currentDate, setCurrentDate] = useState(
        getFirstDayOfTheMonth(new Date())
    );

    const [events, setEvents] = useState([]);
    const [, { showHeader }] = useHeader();
    const [, { showBottomNav }] = useBottomNav();

    const tileClassName = ({ date, view }) => {
        const classes = [];

        if (view === "month") {
            const eventStart = events.find((event) => {
                const eventStartDate = new Date(event.start_date);

                return isSameDay(eventStartDate, date);
            });

            if (eventStart) {
                classes.push("highlight");

                const eventStartDate = new Date(eventStart.start_date);
                const eventEndDate = new Date(eventStart.end_date);

                if (!isSameDay(eventStartDate, eventEndDate)) {
                    classes.push("highlight--range-start");
                }
            }

            const eventEnd = events.find((event) => {
                const eventEndDate = new Date(event.end_date);

                return isSameDay(eventEndDate, date);
            });

            if (eventEnd) {
                const eventStartDate = new Date(eventEnd.start_date);
                const eventEndDate = new Date(eventEnd.end_date);

                if (!isSameDay(eventStartDate, eventEndDate)) {
                    classes.push("highlight--range-end");
                }
            }

            const eventBetween = events.find((event) => {
                const eventStartDate = new Date(event.start_date);
                const eventEndDate = new Date(event.end_date);

                if (!isSameDay(eventStartDate, eventEndDate)) {
                    return (
                        !isSameDay(eventStartDate, date) &&
                        !isSameDay(eventEndDate, date) &&
                        eventStartDate <= date &&
                        eventEndDate >= date
                    );
                }

                return false;
            });

            if (eventBetween) {
                classes.push("highlight--range-between");
            }

            if (isSameDay(today, date)) {
                classes.push("current");
            }

            if (!isSameMonth(currentDate, date)) {
                classes.push("different-month");
            }
        }

        return classes.join(" ");
    };

    const eventsFiltered = events
        .filter((event) => {
            const startDate = new Date(event.start_date);
            const endDate = new Date(event.end_date);

            return (
                startDate.getMonth() <= currentDate.getMonth() &&
                endDate.getMonth() >= currentDate.getMonth()
            );
        })
        .sort((a, b) => {
            const aStartDate = new Date(a.start_date);
            const bStartDate = new Date(b.start_date);

            return aStartDate.getDate() <= bStartDate.getDate() ? -1 : 1;
        })
        .map((event, index) => {
            return (
                <EventCard
                    key={index}
                    startDate={event.start_date}
                    endDate={event.end_date}
                    name={event.name}
                    description={event.description}
                />
            );
        });

    const ref = createRef();

    useEffect(() => {
        showHeader();
        showBottomNav();

        axios
            .get(`${process.env.STRAPI_API_URL}/events`)
            .then((response) => {
                setEvents(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <>
            <Container className="events">
                <Row>
                    <Col>
                        <h1 className="h2 mb-0">Kalendarz wydarzeń</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CalendarNavigation
                            date={currentDate}
                            setDate={setCurrentDate}
                        />
                        <Calendar
                            inputRef={ref}
                            minDetail="month"
                            maxDetail="month"
                            locale="pl-PL"
                            value={currentDate}
                            onClickDay={(_) => setCurrentDate(currentDate)}
                            tileClassName={tileClassName}
                            showNavigation={false}
                        />
                    </Col>
                </Row>
                {eventsFiltered.length ? (
                    <Row className="mt-5">
                        <Col>
                            <PlainText
                                style={{ fontWeight: 700, fontSize: "1.6rem" }}
                            >
                                Wydarzenia
                            </PlainText>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}
                <Row>
                    <Col>{eventsFiltered}</Col>
                </Row>
            </Container>
        </>
    );
};

export default Events;
