import React from "react";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";

import {
    getPreviousMonth,
    getNextMonth,
    getFirstDayOfTheMonth,
} from "../../../utils/dates";

import "./calendarNavigation.scss";

export const CalendarNavigation = ({ date, setDate }) => {
    const labelMonth = date.toLocaleString("pl-pl", { month: "long" });
    const labelYear = date.toLocaleString("pl-pl", { year: "numeric" });

    return (
        <>
            <div className="calendar-navigation">
                <div className="calendar-navigation--label">
                    <span className="calendar-navigation--label-month">
                        {labelMonth}
                    </span>
                    &nbsp;
                    <span className="calendar-navigation--label-year">
                        {labelYear}
                    </span>
                </div>

                <div className="btn--group">
                    <Button
                        className="btn--circle"
                        onClick={(_) =>
                            setDate(
                                getFirstDayOfTheMonth(getPreviousMonth(date))
                            )
                        }
                        aria-label="Poprzedni miesiąc"
                    >
                        <Icon className="icon-arrow-back" />
                    </Button>
                    <Button
                        className="btn--circle"
                        onClick={(_) =>
                            setDate(getFirstDayOfTheMonth(getNextMonth(date)))
                        }
                        aria-label="Następny miesiąc"
                    >
                        <Icon className="icon-arrow-back" />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CalendarNavigation;
