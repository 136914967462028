import React from "react";
import { isSameMonth } from "../../../utils/dates";

import "./eventCard.scss";

export const EventCard = ({ startDate, endDate, name, description }) => {
    const _startDate = new Date(startDate);
    const startDay = _startDate.getDate();
    const startMonth = _startDate.getMonth() + 1;

    const _endDate = new Date(endDate);
    const endDay = new Date(_endDate).getDate();
    const endMonth = new Date(_endDate).getMonth() + 1;

    return (
        <div className="event-card">
            <div className="event-card--day-block">
                <div className="event-card--day">
                    {startDay === endDay
                        ? `${startDay}`
                        : isSameMonth(_startDate, _endDate)
                        ? `${startDay}-${endDay}`
                        : `${startDay}.${startMonth
                              .toString()
                              .padStart(2, "0")}-${endDay}.${endMonth
                              .toString()
                              .padStart(2, "0")}`}
                </div>
            </div>
            <div className="event-card--event-block">
                <div className="event-card--name">{name}</div>
                <div className="event-card--details">{description}</div>
            </div>
        </div>
    );
};

export default EventCard;
