import React from "react";

import Layout from "../layout";
import SEO from "../components/seo";

import EventsView from "../components/organisms/Events/Events";

const Events = () => (
    <Layout>
        <SEO title="Wydarzenia" />
        <EventsView />
    </Layout>
);

export default Events;
